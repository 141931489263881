import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

const FirestoreForm = () => {
    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);
    const RYDERS_TABLE = 'dev/tux/ryders';

    const [formData, setFormData] = useState({ firstName: '', lastName: '', email: '', field4: '' });

    const documentId = "3u6vO50PkkwjvgqbALpH"; 
    const collectionName = RYDERS_TABLE; 

    useEffect(() => {
        const loadFormData = async () => {
            const docRef = doc(db, collectionName, documentId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                setFormData(docSnap.data());
            } else {
                console.log("No such document!");
            }
        };

        loadFormData();
    }, [documentId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const docRef = doc(db, collectionName, documentId);
        await setDoc(docRef, formData);

        alert("Data saved successfully!");
    };

    // if (!auth || !auth?.loggedIn) {
    //     nav('/signin');
    //     return;
    //   }


    return (
        <form onSubmit={handleSubmit}>
            <label htmlFor="firstName">First Name:</label>
            <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
            /><br /><br />
            
            <label htmlFor="lastName">Last Name:</label>
            <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
            /><br /><br />
            
            <label htmlFor="email">Email:</label>
            <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
            /><br /><br />

            <label htmlFor="phone">Phone:</label>
            <input
                type="phone"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
            /><br /><br />            
            
            <button type="submit">Save</button>
        </form>
    );
};

export default FirestoreForm;