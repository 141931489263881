import React from 'react';
import { useParams } from 'react-router-dom';

function ConfirmPayment() {
    const params = useParams();
    return (<div>
        <h3>Confirmation Code: {params.confirmationCode}</h3>
    </div>);
}

export default ConfirmPayment;