import './DriverInfo.css';
import React, { useContext, useState, useEffect } from 'react';

import { getDriverInfo } from '../libs/utils.js';

import { getFirestore } from 'firebase/firestore';

import { FirebaseContext } from '../providers/FirebaseContext.js';


function DriverInfo(props) {
    const [driver, setDriver] = useState(null);
    const {firebaseApp} = useContext(FirebaseContext);
    const db = getFirestore(firebaseApp);

    useEffect(() => {
        if (props.driverId) {
            getDriverInfo(db, props.driverId).then((driver) => setDriver(driver));
        }
    });
    return (<div className="DriverInfo">
                {driver && (
                <>
                    Driver: {driver.personalInfo.firstName} {driver.personalInfo.lastName}
                </>)}
                {!driver && (<>Driver: Not Assigned</>)}
            </div>);
}

export default DriverInfo;