import './CarInfo.css';
import React, { useContext, useState, useEffect } from 'react';

import { getCarInfo } from '../libs/utils.js';

import { getFirestore } from 'firebase/firestore';

import { FirebaseContext } from '../providers/FirebaseContext.js';


function CarInfo(props) {
    const [car, setCar] = useState(null);
    const {firebaseApp} = useContext(FirebaseContext);
    const db = getFirestore(firebaseApp);

    useEffect(() => {
        if (props.carId && props.carId !== 'driver') {
            getCarInfo(db, props.carId).then((car) => setCar(car));
        }
    });
    return (<div className="CarInfo">
                {car && (
                <>
                    Car: {car.make} {car.model}, {car.licensePlate}
                </>)}
                {props.carId === 'driver' && (<>Car: Driver's Car</>)}
            </div>);
}

export default CarInfo;