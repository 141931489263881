import './BookRide.css';
import React, { useState, useContext, useEffect, ChangeEvent, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import {Map, Marker, useMapsLibrary} from '@vis.gl/react-google-maps';

import MuiPhoneNumber from 'mui-phone-number';

import { getFirestore, addDoc, collection, doc, runTransaction } from "firebase/firestore";

import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import AutoCompleteTextField from '../components/AutoCompleteTextField';
import SignIn from './SignIn';
import Signup from './Signup';
import RideLeg from '../components/RideLeg';
import StripePayment from './StripePayment';
import RideMap from '../components/RideMap';

import { calculatePrice } from '../libs/prices.js';
import { getServiceLevel, setUpPhoneNumber, printDate } from '../libs/utils.js';
import { BOOKED_RIDES_TABLE, CONFIRMATION_TABLE, SAFE, SECURE, ULTRA_SECURE, VIEWPORT_LIMIT, PHONE, IS_PROD } from '../libs/constants.js';


function BookRide() {
  const [step, setStep] = useState('area');
  const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
  const {auth, setAuth} = useContext(AuthContext);

  const [metadata, setMetadata] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [payment, setPayment] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [price, setPrice] = useState(0);
  const [fee, setFee] = useState(0);
  const [tip, setTip] = useState(0);
  const [confirmationCode, setConfirmationCode] = useState(0);
  const [serviceLevel, setServiceLevel] = useState(null);
  const [consentToTexting, setConsentToTexting] = useState(false);
  const [legs, setLegs] = useState([]);
  const [areaOfService, setAreaOfService] = useState(null);

  const [signInOpen, setSignInOpen] = useState(false);
  const [signUpOpen, setSignUpOpen] = useState(false);

  const {firebaseApp} = useContext(FirebaseContext);
  const db = getFirestore(firebaseApp);

  const nav = useNavigate();

  const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const onChangeServiceLevel = (event: ChangeEvent) => {
     setServiceLevel(event.target.value);
     const newFee = calculatePrice({rideData: rideDataList, serviceLevel: event.target.value, areaOfService: areaOfService});
     setFee(newFee);
     setPrice(newFee + tip);
  };

  const backToMetadata = () => {
    setPayment(false);
    setMetadata(true);
  };

  const getLegs = () => {
    return rideDataList.map((rideData, i) => {
        const pickUpDateTime = dayjs.tz(`${rideData.date.year()}/${rideData.date.month() + 1}/${rideData.date.date()} ${rideData.time.hour()}:${rideData.time.minute()}`, areaOfService === 'ja'? 'Jamaica': 'America/Los_Angeles').format();
        return {
           from: rideData.from,
           to: rideData.to,
           fromPlaceId: rideData.fromPlaceId,
           toPlaceId: rideData.toPlaceId,
           pickUpDateTime: pickUpDateTime,
           passengers: rideData.passengers,
           driver: rideData.driverId,
           bags: {
               luggage: rideData.bags.luggage,
               overSizedBags: rideData.bags.overSizedBags,
               irregularBags: rideData.bags.irregularBags
               },
           allDay: rideData.allDay,
           estimatedDuration: rideData.estimatedDuration,
           driverId: rideData.driverId
        };
    });
  };

  const confirmCode = async () => {
    if (confirmationCode === 0) {
        const seqDocRef = doc(db, CONFIRMATION_TABLE, "only");
        const code = await runTransaction(db, async (transaction) => {
            const seqDoc = await transaction.get(seqDocRef);
             if (!seqDoc.exists()) {
               throw "Document does not exist!";
             }
             const newCode = seqDoc.data().sequence + 1;
             transaction.update(seqDocRef, { sequence: newCode });
             return newCode;

        });
        setConfirmationCode(code);
    }
  };

  const submit = async (paymentConfirm) => {
    setMetadata(false);

    const tmpLegs = getLegs();
    setLegs(tmpLegs);
    addDoc(collection(db, BOOKED_RIDES_TABLE),
        {
          legs: tmpLegs,
          primary: {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    phone: phoneNumber,
                    consentToTexting: consentToTexting
                    },
          status: 'ACTIVE',
          ryder: 'admin',
          level: serviceLevel,
          price: {total: price, fee: fee, tip: tip},
          confirmationCode: confirmationCode,
          paymentConfirm: paymentConfirm,
        }
      ).then(()=> {
        setIsSubmitted(true);
    });

  };

  const [rideDataList, setRideDataList] = useState([{}]);

  const handleAddLegClick = ()=> {
    let updatedRideDataList = []
    for (const rideData of rideDataList) {
        updatedRideDataList.push(rideData);
    }
    updatedRideDataList.push({});
    setRideDataList(updatedRideDataList);
  };

  const removeLeg = (index) => {
      let updatedRideDataList = [];
      for (let i = 0; i < rideDataList.length; i++) {
          if (i !== index) {
              updatedRideDataList.push(rideDataList[i]);
          }
      }
      setRideDataList(updatedRideDataList);
      if (serviceLevel && areaOfService) {
          const newFee = calculatePrice({rideData: updatedRideDataList, serviceLevel: serviceLevel, areaOfService: areaOfService});
          setFee(newFee);
          setPrice(newFee + tip);
      }
  };

  const onChangeRideLeg = (index, updatedRideData) => {
    let updatedRideDataList = [];
    for (let i = 0; i < rideDataList.length; i++) {
        if (i === index) {
            updatedRideDataList.push(updatedRideData);
        } else {
            updatedRideDataList.push(rideDataList[i]);
        }
    }
    setRideDataList(updatedRideDataList);
    if (serviceLevel && areaOfService) {
        const newFee = calculatePrice({rideData: updatedRideDataList, serviceLevel: serviceLevel, areaOfService: areaOfService});
        setFee(newFee);
        setPrice(newFee + tip);
    }
  };

  const handleSignInOpen = () => {
    setSignInOpen(true);
  };

  const handleSignUpOpen = () => {
     setSignUpOpen(true);
  };

  const handleTip = (event) => {
    let newTip = parseInt(event.target.value);
    if (isNaN(newTip)) {
        setTip('');
        setPrice(fee);
    } else {
        setTip(newTip);
        setPrice(fee + newTip);
    }
  };

  useEffect(() => {
      function handleResize() {
          setViewPortWidth(window.innerWidth);
      }
      window.addEventListener('resize', handleResize)
  });

  const detailsCompleted = () => {
    let completed = true;
    for(const rideData of rideDataList ) {
        if (rideData?.estimatedDuration?.value === undefined ||
            rideData?.date === undefined ||
            rideData?.time === undefined) {
            completed = false;
        }
    }
    return completed;
  };

  const detailsCompletedHeader = () => {
    for(const rideData of rideDataList ) {
        return rideData.from;
    }
    return '';
  };

  const levelCompleted = () => {
    return (serviceLevel !== undefined && serviceLevel !== null)
  };

  const areaOfServiceCompleted = () => {
    return (areaOfService !== null);
  };

  const passengerCompleted = () => {
    return (firstName !== '' &&
            lastName !== '' &&
            email !== '' &&
            phoneNumber !== '')
  };

  return (
    <div className="BookRide">
       {metadata && ( <Stack spacing={1}>
        <Accordion expanded={step === 'area'} onChange={(event) => setStep('area')}>
           <AccordionSummary
             expandIcon={<ExpandMoreIcon />}
             id="area"
           >
               <div className="Summary">
                   <div className="SummaryHeader"><span>Service Area</span> { step !== 'area' && areaOfServiceCompleted() && <><span className="HeaderCompleted">: {areaOfService === 'ja' ? 'Jamaica': 'Sacramento, CA'}</span> <CheckCircleOutlineIcon color="success" /> </> }</div>
               </div>
           </AccordionSummary>
           <AccordionDetails>
               <Stack direction="column" spacing={4}>
                   <Stack spacing={2} direction="row">
                       <RadioGroup row value={areaOfService} onChange={(event) => {setAreaOfService(event.target.value)}}>
                           <FormControlLabel value={'ja'} control={<Radio/>} label="Jamaica"/>
                           <FormControlLabel value={'sac'} control={<Radio/>} label="Sacramento, CA"/>
                       </RadioGroup>
                   </Stack>

                   <div>
                       <Button variant="outlined" onClick={() => setStep('details')}>Continue</Button>
                   </div>
               </Stack>
           </AccordionDetails>
       </Accordion>

        <Accordion expanded={step === 'details'} onChange={(event) => setStep('details')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                id="details">
                <div className="Summary">
                    <div className="SummaryHeader"><span>Ryde Details</span> { step !== 'details' && detailsCompleted() && <><span className="HeaderCompleted">: {detailsCompletedHeader()}</span> <CheckCircleOutlineIcon color="success"/> </> }</div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                    <Stack spacing={2} direction="column">
                    {
                        rideDataList.map( (rideData, i) => {
                            return (
                                <Stack key={`rideLegsStack_${i}`}>
                                    <Card variant="outlined" key={`rideLegsCard_${i}`}>
                                        <CardContent key={`rideLegsCardContent_${i}`}>
                                    {rideDataList.length > 1 && (<div className="LegCloseButton" onClick={() => { removeLeg(i); }}><Button><CloseIcon/></Button></div>) }
                                        <RideLeg key={`rideData_${i}`} onChange={(updatedRideData) => { onChangeRideLeg( i, updatedRideData); }} region={areaOfService}/>
                                        </CardContent>
                                    </Card>
                                </Stack>
                            );
                        })
                    }
                    <div>
                        <Button variant="outlined" onClick={() => setStep('level')} disabled={!detailsCompleted()}>Continue</Button>
                    </div>
                    </Stack>

            </AccordionDetails>
        </Accordion>

        <Accordion expanded={step === 'level'} onChange={(event) => setStep('level')} disabled={!detailsCompleted()}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              id="level"
            >
                <div className="Summary">
                    <div className="SummaryHeader"><span>Service Level</span> {step !== 'level' && levelCompleted() && <><span className="HeaderCompleted">: {getServiceLevel(serviceLevel)}</span> <CheckCircleOutlineIcon color="success"/></> }</div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <Stack direction="column" spacing={4}>
                    <Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                        <Stack spacing={2} direction="column">
                            <Stack spacing={2} direction="row">
                                <RadioGroup row value={serviceLevel} onChange={onChangeServiceLevel}>
                                    <FormControlLabel value={SAFE} control={<Radio/>} label="Safe"/>
                                    <FormControlLabel value={SECURE} control={<Radio/>} label="Secure"/>
                                </RadioGroup>
                            </Stack>
                            {serviceLevel === SAFE && (
                            <div>
                                Our Safe service is a high-quality ride with a vetted professional driver and a nice, clean, well-maintained vehicle.
                            </div>)}
                            {serviceLevel === SECURE && (
                            <div>
                                Our Secure service is a high-quality ride with an armed professional driver and a nice, clean, well-maintained vehicle.
                            </div>)}
                        </Stack>
                        <div>
                            <h3>Service Fee: {priceFormatter.format(fee)} </h3>
                        </div>
                    </Stack>
                    <div>
                        <Button variant="outlined" onClick={() => setStep('passenger')} disabled={!levelCompleted()}>Continue</Button>
                    </div>
                </Stack>
            </AccordionDetails>
        </Accordion>

        <Accordion expanded={step === 'passenger'} onChange={(event) => setStep('passenger')} disabled={!levelCompleted() || !detailsCompleted()}>
                 <AccordionSummary
                   expandIcon={<ExpandMoreIcon />}
                   id="passenger"
                 >
                 <div className="Summary">
                     <div className="SummaryHeader"><span>Primary Passenger</span>{ step !== 'passenger' && passengerCompleted() && <><span className="HeaderCompleted">: {firstName} {lastName}</span> <CheckCircleOutlineIcon color="success"/></> }</div>
                 </div>
                </AccordionSummary>
        <AccordionDetails>
                    <Stack spacing={4} direction="column">
                        <h4>Primary Passenger Information</h4>
                        <Stack spacing={viewPortWidth > VIEWPORT_LIMIT ? 5: 2} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                            <TextField label="First Name" variant="outlined" type="text" value={firstName} onChange={(event) => { setFirstName(event.target.value); }} sx={{minWidth: 300, maxWidth: 500}}/>
                            <TextField label="Last Name" variant="outlined" type="text" value={lastName} onChange={(event) => { setLastName(event.target.value); }} sx={{minWidth: 300, maxWidth: 500}}/>
                        </Stack>
                        <Stack spacing={2}>
                            <TextField label="Email" variant="outlined" type="email" value={email} onChange={(event) => { setEmail(event.target.value); }} sx={{minWidth: 300, maxWidth: 500}}/>
                            <div>Needed for confirmations and reservation changes</div>
                        </Stack>
                        <Stack spacing={2} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                            <MuiPhoneNumber disableAreaCodes={true} variant="outlined" label="Phone Number" defaultCountry={'us'} value={phoneNumber} onChange={(value) => { let newPhoneNumber = setUpPhoneNumber(phoneNumber, value);  setPhoneNumber(newPhoneNumber); }} />
                            <div>
                                <Checkbox checked={consentToTexting}  onChange={(event) => { setConsentToTexting(event.target.checked);}} /> I consent to receive text messages about my ryde. Message and data rates may apply.
                            </div>
                        </Stack>
                        <div>
                            <Button variant="outlined" onClick={async() => {setStep('payment'); await confirmCode();} }  disabled={!passengerCompleted()}>Continue</Button>
                        </div>
                    </Stack>

            </AccordionDetails>
        </Accordion>

        <Accordion expanded={step === 'payment'} onChange={async (event) => {setStep('payment'); await confirmCode();}} disabled={!passengerCompleted() || !levelCompleted() || !detailsCompleted()}>
                 <AccordionSummary
                   expandIcon={<ExpandMoreIcon />}
                   id="payment"
                 >
                 <div className="Summary">
                      <div className="SummaryHeader">Payment</div>
                  </div>
                </AccordionSummary>
        <AccordionDetails>

                <Stack direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                    <div className="StripePayment">
                        <StripePayment price={price} paymentSubmitted={submit} valid={()=> true} email={auth?.user?.email} confirmationCode={confirmationCode} live={IS_PROD}/>
                    </div>
                    <Stack direction="column">
                        <div className="Total">
                            Total Due: {!isNaN(fee) ? priceFormatter.format(fee + tip): '0'}
                        </div>
                    </Stack>
                </Stack>

        </AccordionDetails>
                </Accordion>

        </Stack>)}
        {isSubmitted && (
            <div className="Confirmation">
                <div className="ThankYou">Thank You for Booking with TuxRydes</div>
                <div className="TheSmallPrint">100% of Tips Go to Drivers</div>
                <div className="ConfirmationCode">Confirmation: {confirmationCode}</div>
                <div className="CostBreakDown">
                    <div className="CostRow">
                        <div className="CostCell">TuxRydes Fee</div>
                        <div className="CostCell">{priceFormatter.format(fee)}</div>
                    </div>
                </div>
                <div className="CostTotal">
                    <div className="CostRow">
                        <div className="CostCell">CC (Paid)</div>
                        <div className="CostCell">{priceFormatter.format(price)}</div>
                    </div>
                </div>
                {
                    legs.map( (leg, i) => {
                        return (
                        <div>
                            <h4>Ryde {i + 1}</h4>
                            <RideMap from={leg.fromPlaceId} to={leg.toPlaceId} duration={leg.estimatedDuration.value}/>
                            <h4>Pick-up: {printDate(leg.pickUpDateTime)}</h4>
                            <p>{leg.from}</p>
                            <h4>Drop-off</h4>
                            <p>{leg.to}</p>
                            <h4>Estimated Duration</h4>
                            <p>{leg.estimatedDuration.text}</p>
                        </div>
                        );
                    })
                }
                <div className="ConfirmContact">For assistance with this reservation, call: {PHONE}</div>

            </div>
            )}
    </div>
  );
}

export default BookRide;
