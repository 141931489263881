import './RideDetails.css';
import '../pages/BookRide.css';
import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { getRideStatus, getServiceLevel, getDriverInfo, getCarInfo, getPaymentHistory } from '../libs/utils.js';
import {IS_PROD, STRIPE_REFUND_URL, STRIPE_REFUND_DEV_URL, BOOKED_RIDES_TABLE } from '../libs/constants.js';

import { doc, setDoc, getFirestore } from 'firebase/firestore';

import { FirebaseContext } from '../providers/FirebaseContext.js';

import RideMap from'./RideMap';
import CarInfo from './CarInfo';
import DriverInfo from './DriverInfo';

function CancelRide(props) {
    const ride = props.ride;
    const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
    const {firebaseApp} = useContext(FirebaseContext);
    const db = getFirestore(firebaseApp);

    const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
    const [status, setStatus] = useState(getRideStatus(ride));

    const getIntentId = (paymentHistory) => {
        const amount = ride.price.total;
        for (const paymentInfo of paymentHistory) {
            if ('paymentIntent' in paymentInfo) {
                if (paymentInfo.paymentIntent.amount > amount && paymentInfo.paymentIntent.object === "payment_intent") {
                    return paymentInfo.paymentIntent.id;
                }
            } else {
                if (paymentInfo.amount > amount && paymentInfo.object === "payment_intent") {
                    return paymentInfo.id;
                }
            }
        }
        return null;
    };

    const refundRide = async (paymentHistory) => {
        try {
            const refundRes = await fetch(IS_PROD ? STRIPE_REFUND_URL : STRIPE_REFUND_DEV_URL, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({payment_intent: getIntentId(paymentHistory),
                                    amount: Math.round(ride.price.total * 100)})
            });
            const refund = await refundRes.json();
            const updatedPaymentHistory = [...paymentHistory];
            updatedPaymentHistory.push(refund);
            return updatedPaymentHistory;
        } catch (error) {
            console.error(error);
            return;
        }

    };

    const onYesCancelClick = () => {
        const paymentHistory = getPaymentHistory(ride.paymentConfirm);
        refundRide(paymentHistory).then((updatedPaymentHistory) => {
            const rydeRef = doc(db, BOOKED_RIDES_TABLE, ride.id);
            setDoc(rydeRef,
                { status: 'CANCELED', paymentConfirm: updatedPaymentHistory },
                { merge: true }).then(() => {
                setCancelDialogOpen(false);
                props.onAction();
            });
        });
    };

    return (
        <div className="RideDetails">
            <div className="DetailsTitle"><Button onClick={() => props.onBack() }><ArrowBackIcon /></Button> Cancel Ride</div>
            <div className="ConfirmationCode">Confirmation: {ride.confirmationCode}</div>
            <div className="Status">Staus: {status} </div>
            <div className="ServiceLevel">Service Level: {getServiceLevel(ride.level)}</div>
            <div className="CostBreakDown">
                <div className="CostRow">
                    <div className="CostCell">TuxRydes Fee</div>
                    <div className="CostCell">{priceFormatter.format(ride.price.fee)}</div>
                </div>
            </div>
            <div className="CostTotal">
                <div className="CostRow">
                    <div className="CostCell">CC (Paid)</div>
                    <div className="CostCell">{priceFormatter.format(ride.price.total)}</div>
                </div>
            </div>
            {
                ride.legs.map( (leg, i) => {
                    return (
                    <div>
                        <h4>Ryde {i + 1}</h4>
                        <DriverInfo driverId={leg.driverId}/>
                        <CarInfo carId={leg.carId}/>
                        <RideMap from={leg.fromPlaceId} to={leg.toPlaceId} duration={leg?.estimatedDuration?.value || 'N/A'}/>
                        <h4>Pickup: {leg.pickUpDateTime}</h4>
                        <p>{leg.from}</p>
                        <h4>Drop-off</h4>
                        <p>{leg.to}</p>
                        <h4>Estimated Duration</h4>
                        <p>{leg?.estimatedDuration?.text || 'N/A'}</p>
                    </div>
                    );
                })
            }
            <div>
                <Button variant="outlined" onClick={() => setCancelDialogOpen(true)}>Cancel Ride</Button>
                <Dialog open={cancelDialogOpen} onClose={()=> setCancelDialogOpen(false)} >
                    <DialogTitle id="alert-dialog-title">{"Are you sure that you want to cancel your Ryde?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                        Are you sure that you want to cancel?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={onYesCancelClick}>Yes</Button>
                      <Button onClick={() => setCancelDialogOpen(false)} autoFocus>
                        No
                      </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>);
}

export default CancelRide;