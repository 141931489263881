import './RideLeg.css';
import React, { useContext, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { getFirestore, addDoc, collection, getDocs } from "firebase/firestore";

import {Map, Marker, useMap, useMapsLibrary} from '@vis.gl/react-google-maps';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import AutoCompleteTextField from './AutoCompleteTextField';
import { VIEWPORT_LIMIT, MAX_PASSENGERS, CARS_TABLE, DRIVERS_TABLE } from '../libs/constants.js';

import { FirebaseContext } from '../providers/FirebaseContext.js';

const routeService = { current: null };

function RideLeg(props) {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const timeZone = props.region === 'ja'? 'Jamaica': 'America/Los_Angeles';
    const initialDay = props?.leg?.pickUpDateTime ?  dayjs(props?.leg?.pickUpDateTime).tz(timeZone) : dayjs().add(1, 'day').tz(timeZone);
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [pickUp, setPickUp] = useState(props?.leg?.from || null);
    const [dropOff, setDropOff] = useState(props?.leg?.to || null);
    const [pickUpPlaceId, setPickUpPlaceId] = useState(props?.leg?.fromPlaceId || null);
    const [dropOffPlaceId, setDropOffPlaceId] = useState(props?.leg?.toPlaceId || null);
    const [date, setDate] = useState(initialDay);
    const [time, setTime] = useState(initialDay);
    const [passengers, setPassengers] = useState(props?.leg?.passengers || 1);
    const [bags, setBags] = useState(props?.leg?.bags?.luggage || 1);
    const [overSizedBags, setOverSizedBags] = useState(props?.leg?.bags?.overSizedBags || 0);
    const [irregularBags, setIrregularBags] = useState(props?.leg?.bags?.irregularBags || 0);
    const [allDay, setAllDay] = useState(props?.leg?.allDay || false);
    const [driverId, setDriverId] = useState(props?.leg?.driverId);
    const [carId, setCarId] = useState(props?.leg?.carId || 'driver');
    const [drivers, setDrivers] = useState([]);
    const [cars, setCars] = useState([]);

    const [passengersAnchor, setPassengersAnchor] = useState(null);

    const {firebaseApp} = useContext(FirebaseContext);
    const db = getFirestore(firebaseApp);

    const handlePassengersClick = (event) => {
        setPassengersAnchor(passengersAnchor ? null : event.currentTarget);
    };

    const passengersOpen = Boolean(passengersAnchor);
    const passengersId = passengersOpen ? 'passengers-popper' : undefined;

    const [bagsAnchor, setBagsAnchor] = useState(null);

    const [estimatedDuration, setEstimatedDuration] = useState(props?.leg?.estimatedDuration);
    const map = useMap();
    const routeLib = useMapsLibrary('routes');

    const handleBagsClick = (event) => {
        setBagsAnchor(bagsAnchor ? null : event.currentTarget);
    };

    const updateEstimatedDuration = () => {
        if (!routeLib) {
            return;
        }

        if (!routeService.current) {
            routeService.current = new routeLib.DistanceMatrixService();
        }

        if (!routeService.current) {
            return;
        }

        if (dropOffPlaceId === null || pickUpPlaceId === null ||
           dropOffPlaceId === 'null' || pickUpPlaceId === 'null' ||
           dropOffPlaceId === undefined || pickUpPlaceId === undefined) {
            setEstimatedDuration({});
            onChange({updatedEstimatedDuration: {}});
            return;
        }

        routeService.current.getDistanceMatrix({
            destinations: [{placeId: dropOffPlaceId}],
            origins: [{placeId: pickUpPlaceId}],
            travelMode: routeLib.TravelMode.DRIVING}, (response, status) => {
                const duration = response.rows[0].elements[0].duration;
                setEstimatedDuration(duration);
                onChange({updatedEstimatedDuration: duration});
            });

    };

    const bagsOpen = Boolean(bagsAnchor);
    const bagsId = bagsOpen ? 'bags-popper' : undefined;

    const getRideLegInfo = (updated) => {
        return {
            from: updated?.updatedFrom || pickUp,
            fromPlaceId: updated?.updatedFromPlaceId || pickUpPlaceId,
            to: updated?.updatedTo || dropOff,
            toPlaceId: updated?.updatedToPlaceId || dropOffPlaceId,
            date: updated?.updatedDate || date,
            time: updated?.updatedTime || time,
            passengers: updated?.updatedPassengers || passengers,
            bags: {
                luggage: updated?.updatedBags || bags,
                overSizedBags: updated?.updatedOverSizedBags || overSizedBags,
                irregularBags: updated?.updatedIrregularBags || irregularBags
                },
            allDay: ('updatedAllDay' in updated) ? updated.updatedAllDay : allDay,
            estimatedDuration: updated?.updatedEstimatedDuration ||estimatedDuration,
            driverId: updated?.updatedDriverId || driverId,
            carId: updated?.updatedCarId || carId,
            };
    };

    const onChange = (updated) => {
        if (props.onChange) {
            props.onChange(getRideLegInfo(updated));
        }
    };

    const onChangeTime = (value) => {
        if (value) {
          const newTime = value.tz(timeZone);
          setTime(newTime);
          onChange({updatedTime: newTime});
        }
    };

    const onChangeDate = (value) => {
        if (value) {
          const newDate = value.tz(timeZone);
          setDate(newDate);
          onChange({updatedDate: newDate});
        }
    };

    const incPassengers = () => {
        if (passengers < MAX_PASSENGERS) {
            setPassengers(passengers + 1);
            onChange({updatedPassengers: passengers - 1});
        }
    };

    const decPassengers = () => {
        if (passengers > 1) {
            setPassengers(passengers - 1);
            onChange({updatedPassengers: passengers - 1});
        }
    };

    const incBags = () => {
        if (bags < 4) {
            setBags(bags + 1);
            onChange({updatedBags: bags + 1});
        }
    };

    const decBags = () => {
        if (bags > 0) {
            setBags(bags - 1);
            onChange({updatedBags: bags - 1});
        }
    };

    const incOverSizeBags = () => {
        if (overSizedBags < 2) {
            setOverSizedBags(overSizedBags + 1);
            onChange({updatedOverSizedBags: overSizedBags + 1});
        }
    };

    const decOverSizeBags = () => {
        if (overSizedBags > 0) {
            setOverSizedBags(overSizedBags - 1);
            onChange({updatedOverSizedBags: overSizedBags - 1});
        }
    };

    const incIrregularBags = () => {
        if (irregularBags < 2) {
            setIrregularBags(irregularBags + 1);
            onChange({updatedIrregularBags: irregularBags + 1});
        }
    };

    const decIrregularBags = () => {
        if (irregularBags > 0) {
            setIrregularBags(irregularBags - 1);
            onChange({updatedIrregularBags: irregularBags - 1});
        }
    };

    const setFrom = (from) => {
        setPickUp(from);
        onChange({updatedFrom: from});
    };

    const setTo = (to) => {
        setDropOff(to);
        onChange({updatedTo: to});
    };

    const setFromPlaceId = (placeId) => {
        setPickUpPlaceId(placeId);
        onChange({updatedFromPlaceId: placeId});
    };

    const setToPlaceId = (placeId) => {
        setDropOffPlaceId(placeId);
        onChange({updatedToPlaceId: placeId});
    };

    const fetchDrivers = async () => {
        getDocs(collection(db, DRIVERS_TABLE))
              .then((querySnapshot)=>{
                  const newData = querySnapshot.docs
                      .map((doc) => ({...doc.data(), id:doc.id }));
                  setDrivers(newData);
              })
    };

    const fetchCars = async () => {
        getDocs(collection(db, CARS_TABLE))
              .then((querySnapshot)=>{
                  const newData = querySnapshot.docs
                      .map((doc) => ({...doc.data(), id:doc.id }));
                  setCars(newData);
              })
    };

    useEffect(() => {
        fetchCars();
        fetchDrivers();
    }, []);

    useEffect( () => {
        updateEstimatedDuration();
    }, [pickUpPlaceId, dropOffPlaceId, routeLib])

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    return (
    <div className="RideLeg">
        <Stack spacing={2} direction="column">
            <Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                <AutoCompleteTextField key="ride-leg-pickup" label="Pickup Location" variant="outlined" value={pickUp} setValue={setFrom} setPlaceId={setFromPlaceId} region={props.region}/>
                <AutoCompleteTextField key="ride-leg-dropoff" label="Drop-off Location" variant="outlined" value={dropOff} setValue={setTo} setPlaceId={setToPlaceId} region={props.region}/>
                <DatePicker label="Pickup Date" onChange={onChangeDate} value={date} minDate={initialDay}/>
                <TimePicker label="Pickup Time" onChange={onChangeTime} value={time} />
            </Stack>
            <Stack spacing={4} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                <div className="PopupButton">
                    <Button variant="outlined" onClick={handlePassengersClick}>{passengers} Passengers</Button>
                    <BasePopup id={passengersId} open={passengersOpen} anchor={passengersAnchor}>
                        <ClickAwayListener onClickAway={() => {setPassengersAnchor(null);}}>
                            <div className="Popup">People&nbsp;<Button onClick={decPassengers}><RemoveIcon/></Button>{passengers}<Button onClick={incPassengers}><AddIcon/></Button></div>
                        </ClickAwayListener>
                    </BasePopup>
                </div>
                <div className="PopupButton">
                    <Button variant="outlined" onClick={handleBagsClick}>{bags + overSizedBags + irregularBags} Bags</Button>
                    <BasePopup id={bagsId} open={bagsOpen} anchor={bagsAnchor}>
                        <ClickAwayListener onClickAway={() => {setBagsAnchor(null);}}>
                            <div className="Popup">
                                <Stack direction="column">
                                    <div>
                                        <div><span className="InfoTitle">Luggage</span><Button onClick={decBags}><RemoveIcon/></Button>{bags}<Button onClick={incBags}><AddIcon/></Button></div>
                                        <div className="InfoText">Suitcases, Backpacks, etc.</div>
                                     </div>
                                    <div>
                                        <div><span className="InfoTitle">Oversized</span><Button onClick={decOverSizeBags}><RemoveIcon/></Button>{overSizedBags}<Button onClick={incOverSizeBags}><AddIcon/></Button></div>
                                        <div className="InfoText">Video Equipment, Boxes, etc.</div>
                                    </div>
                                    <div>
                                        <div><span className="InfoTitle">Irregular</span><Button onClick={decIrregularBags}><RemoveIcon/></Button>{irregularBags}<Button onClick={incIrregularBags}><AddIcon/></Button></div>
                                        <div className="InfoText">Golf Clubs, Strollers, etc.</div>
                                    </div>
                                </Stack>
                            </div>
                        </ClickAwayListener>
                    </BasePopup>
                  </div>
                <div className="AllDay">
                    <Checkbox checked={allDay}  onChange={(event) => { setAllDay(event.target.checked); onChange({updatedAllDay: event.target.checked}); }} /> All Day Excursion
                </div>

                {estimatedDuration && estimatedDuration.text && (<div className="EstimatedDuration"><div class="Inner">Estimated Duration: {estimatedDuration.text}</div></div>)}
            </Stack>
            <div>
                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Driver</InputLabel>
                       <Select
                         value={driverId}
                         onChange={(event) => { setDriverId(event.target.value); onChange({updatedDriverId: event.target.value});}}
                         label="Driver"
                       >
                         <MenuItem value={null}>
                           <em>None</em>
                         </MenuItem>
                         {
                            drivers.map( (driver, i)  => {
                                return (<MenuItem value={driver.id}>{driver?.personalInfo?.lastName || 'N/A'}, {driver?.personalInfo?.firstName || 'N/A'}</MenuItem>);
                            })
                         }
                       </Select>
                   </FormControl>
                </Box>
            </div>
            <div>
                <Box sx={{ minWidth: 120, maxWidth: 300 }}>
                   <FormControl fullWidth>
                       <InputLabel>Car</InputLabel>
                       <Select
                         value={carId}
                         onChange={(event) => { setCarId(event.target.value); onChange({updatedCarId: event.target.value});}}
                         label="Car"
                       >
                         <MenuItem value={'driver'}>
                           <em>Driver's Car</em>
                         </MenuItem>
                         {
                            cars.map( (car, i)  => {
                                return (<MenuItem value={car.id}>{car.make}, {car.model}, {car.year}</MenuItem>);
                            })
                         }
                       </Select>
                   </FormControl>
                </Box>
            </div>
        </Stack>
    </div>
    );
}

export default RideLeg;