import './Cars.css';
import React, {useContext, useState, useEffect, useMemo} from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";

import { CARS_TABLE } from '../libs/constants.js';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import Car from '../components/Car';

function Cars () {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const [cars, setCars] = useState([]);
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [orderBy, setOrderBy] = useState('make');
    const [order, setOrder] = useState('desc');
    const [mode, setMode] = useState(null);
    const [selectedCar, setSelectedCar] = useState(null);

    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const fetchCars = async () => {
      getDocs(collection(db, CARS_TABLE))
              .then((querySnapshot)=>{
                   const newCars = querySnapshot.docs.map((car) =>
                            ({...car.data(), id: car.id}));
                  setCars(newCars);
                  setLoaded(true);
              })
    };

    useEffect(()=>{
        fetchCars();
    }, []);

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    const filterRows = (rows) => {
      if (filter === null) {
          return rows;
      }
      return rows.filter((row) => {
          const make = row?.make || 'N/A';
          const model = row?.model || 'N/A';
          const year = row?.year || 'N/A';
          const licensePlate = row?.licensePlate || 'N/A';
          const filterLowerCase = filter.toLowerCase();
          return (make.toLowerCase().includes(filterLowerCase)||
                  model.toLowerCase().includes(filterLowerCase) ||
                  year.toLowerCase().includes(filterLowerCase) ||
                  licensePlate.toLowerCase().includes(filterLowerCase));
      });
    };

    const sortRows = (rows) => {
      return rows.sort((a, b) =>  {
          if (orderBy === 'make') {
              if (order === 'asc') {
                  return a.make.localeCompare(b.make);
              } else {
                  return b.make.localeCompare(a.make);
              }
          } else if (orderBy === 'model') {
              if (order === 'asc') {
                  return a.model.localeCompare(b.model);
              } else {
                  return b.model.localeCompare(a.model);
              }
          } else if (orderBy === 'year') {
               if (order === 'asc') {
                   return a.year.localeCompare(b.year);
               } else {
                   return b.year.localeCompare(a.year);
               }
          } else if (orderBy === 'licensePlate') {
                if (order === 'asc') {
                    return a.licensePlate.localeCompare(b.licensePlate);
                } else {
                    return b.licensePlate.localeCompare(a.licensePlate);
                }
          } else {
              return 0;
          }

      })
    };

    const filteredRows = useMemo(() => filterRows(sortRows(cars)), [cars, filter, order, orderBy]);

    const visibleRows = useMemo(
        () => filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, filteredRows, filter, order, orderBy],
      );

    const onColumnClick = (header) => {
      if (header === orderBy) {
          setOrder(order !== 'asc' ? 'asc': 'desc');
      } else {
          setOrderBy(header);
          setOrder('asc');
      }
    };

    /*useEffect(() => {
        if (!auth || !auth?.loggedIn) {
          nav('/signin');
        }
    });*/

    if (selectedCar && mode === 'edit') {
            return (
                  <>
                      <Car car={selectedCar} onBack={() => {setSelectedCar(null); setMode(null);}} onAction={() => {setSelectedCar(null); setMode(null); fetchCars();} } edit={true} />
                  </>
              );
    } else if (mode === 'add') {
            return (
                  <>
                      <Car onBack={() => {setSelectedCar(null); setMode(null);}} onAction={() => {setSelectedCar(null); setMode(null); fetchCars();} } edit={false} />
                  </>
              );
    }


    if (visibleRows.length === 0 && !loaded) {
      return (<div className="Loading"><CircularProgress/></div>);
    }

    return ( <div className="Cars">
        <div className="CarsButton">
          <TextField label="Search" variant="outlined" type="text" value={filter} size={"small"} onChange={(event) => {setFilter(event.target.value)}}/>
          &nbsp;<Button variant="outlined" onClick={() => {setMode('add');} }>Add Car</Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key={'make'}><TableSortLabel active={orderBy === 'make'} onClick={() => {onColumnClick('make'); } } direction={orderBy === 'make' ? order : 'asc'}>Make</TableSortLabel></TableCell>
              <TableCell key={'model'}><TableSortLabel active={orderBy === 'model'} onClick={() => {onColumnClick('model');} } direction={orderBy === 'model'? order : 'asc'}>Model</TableSortLabel></TableCell>
              <TableCell key={'year'}><TableSortLabel active={orderBy === 'year'} onClick={() => {onColumnClick('year');} } direction={orderBy === 'year'? order : 'asc'}>Year</TableSortLabel></TableCell>
              <TableCell key={'licensePlate'}><TableSortLabel active={orderBy === 'licensePlate'} onClick={() => {onColumnClick('licensePlate');} } direction={orderBy === 'licensePlate'? order : 'asc'}>License Plate</TableSortLabel></TableCell>
              <TableCell key={'actions'}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
                visibleRows.map( (car,i) => {
                  const keyName = `${car.type}_${i}`;
                  return (
                    <TableRow key={keyName}>
                      <TableCell>{car.make}</TableCell>
                      <TableCell>{car.model}</TableCell>
                      <TableCell>{car.year}</TableCell>
                      <TableCell>{car.licensePlate}</TableCell>
                      <TableCell><Button onClick={() => {setMode('edit'); setSelectedCar(car)}}><EditIcon /></Button></TableCell>
                    </TableRow>
                  );
                })
            }
            </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>);

  }

export default Cars;
