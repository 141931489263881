import './Profile.css';
import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import MuiPhoneNumber from 'mui-phone-number';

import { BOOK_RIDES_TABLE, RYDERS_TABLE, VIEWPORT_LIMIT } from '../libs/constants.js';

import { doc, setDoc, getFirestore } from 'firebase/firestore';

import { getUserInfo, setUpPhoneNumber } from '../libs/utils.js';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

function Profile () {
    const nav = useNavigate();

    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);

    const {firebaseApp} = useContext(FirebaseContext);
    const {auth, setAuth} = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState(null);
    const [isEdit, setIsEdit] = useState(false);

    const db = getFirestore(firebaseApp);

    const signOut = () => {
        setAuth({loggedIn: false, user: null});
        nav('/signin');
    };

    const saveProfile = () => {
        setIsEdit(false);
        setLoading(true);
        const ryderRef = doc(db, RYDERS_TABLE, userInfo.id);
        const newUserInfo =  { firstName: userInfo.firstName, lastName: userInfo.lastName, phoneNumber: userInfo.phoneNumber };
        setDoc(ryderRef, newUserInfo, { merge: true })
        .then(() => {
            getUserInfo(db, auth?.user.uid).then( (result) => {
                setUserInfo(result);
                setLoading(false);
            } );
        });
    };

    const updateUserInfo = (update) => {
        const updatedUserInfo = {...userInfo};
        if (update?.firstName) {
            updatedUserInfo.firstName = update.firstName;
        }
        if (update?.lastName) {
            updatedUserInfo.lastName = update.lastName;
        }
        if (update?.phoneNumber) {
            updatedUserInfo.phoneNumber = update.phoneNumber;
        }
        setUserInfo(updatedUserInfo);
    };


    useEffect(() => {
      function handleResize() {
          setViewPortWidth(window.innerWidth);
      }
      window.addEventListener('resize', handleResize)
    });

    useEffect(() => {
        if (!loading && !auth?.loggedIn) {
            nav('/signin');
        }
    });

    if (loading) {
        if (auth?.loggedIn) {
            getUserInfo(db, auth?.user.uid).then( (result) => {
                setUserInfo(result);
                setLoading(false);
            } );
            return (<div className="Loading"><CircularProgress/></div>);
        } else {
            setLoading(false);
        }
    }  else  if (userInfo != null) {
        return (<div className="Profile">
                <Card variant="outlined">
                    <CardContent>
                        <div className="SignOut"><Button onClick={signOut}>Sign Out</Button></div>
                    {!isEdit && (<>
                        <Stack spacing={viewPortWidth > VIEWPORT_LIMIT ? 5: 2} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                            <Stack direction="column">
                                <h3>First Name</h3>
                                <div>{userInfo.firstName}</div>
                            </Stack>
                            <Stack direction="column">
                                <h3>Last Name</h3>
                                <div>{userInfo.lastName}</div>
                            </Stack>
                        </Stack>
                        <h3>Email</h3>
                        <div>{userInfo.email}</div>
                        <h3>Phone</h3>
                        <div>{userInfo.phoneNumber}</div>
                        <div className="ProfileButtons"><Button onClick={() => setIsEdit(true)}>Edit Profile</Button></div>
                    </>)}
                    {isEdit && (<>
                        <Stack spacing={viewPortWidth > VIEWPORT_LIMIT ? 5: 2} direction={viewPortWidth > VIEWPORT_LIMIT ? "row": "column"}>
                            <Stack direction="column">
                                <h3>First Name</h3>
                                <div><TextField label="First Name" variant="outlined" value={userInfo.firstName} onChange={(event) => { updateUserInfo({firstName: event.target.value}); } } sx={{minWidth: 300, maxWidth: 500}}/></div>
                            </Stack>
                            <Stack direction="column">
                                <h3>Last Name</h3>
                                <div><TextField label="Last Name" variant="outlined" value={userInfo.lastName} onChange={(event) => { updateUserInfo({lastName: event.target.value}); } } sx={{minWidth: 300, maxWidth: 500}}/></div>
                            </Stack>
                        </Stack>
                        <h3>Email</h3>
                        <div>{userInfo.email}</div>
                        <h3>Phone</h3>
                        <div>
                            <MuiPhoneNumber variant="outlined" label="Phone Number" defaultCountry={'us'} value={userInfo.phoneNumber} onChange={(value) => { let newPhoneNumber = setUpPhoneNumber(userInfo.phoneNumber, value); updateUserInfo({phoneNumber: value}); }} />
                        </div>
                        <div className="ProfileButtons"><Button onClick={() => saveProfile()}>Save Profile</Button><Button onClick={() => setIsEdit(false)}>Cancel</Button></div>
                    </>)}

                    </CardContent>
               </Card>
            </div>);
    } else {
        return (<></>);
    }
}

export default Profile;
