import React, { useState, useContext } from 'react';
import * as XLSX from 'xlsx';
import { getFirestore } from 'firebase/firestore';
import { DRIVERS_SCHEDULE_TABLE } from '../libs/constants';
import { FirebaseContext } from '../providers/FirebaseContext.js';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import ShiftCalendar from './ShiftCalendar'; // Import the ShiftCalendar component

const ShiftScheduler = () => {
  const [jsonData, setJsonData] = useState(null);
  const [error, setError] = useState(null);

  // Use the existing Firebase app from context
  const { firebaseApp } = useContext(FirebaseContext);
  const db = getFirestore(firebaseApp); // Get Firestore instance from the existing app


  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      setError("No file selected.");
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert sheet to JSON
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const parsedData = parseShiftData(json);
        setJsonData(parsedData);
        setError(null); // Clear any previous errors
      } catch (err) {
        setError("Error reading file: " + err.message);
      }
    };

    reader.readAsArrayBuffer(file);
  };

  const parseShiftData = (data) => {
    if (!data || data.length === 0) {
      return [];
    }

    const headers = data[0];
    const days = {};

    // Initialize days based on the number of days in the data
    const numberOfDays = Math.ceil((headers.length - 4) / 3);
    for (let dayIndex = 0; dayIndex < numberOfDays; dayIndex++) {
      days[dayIndex] = {
        day: dayIndex + 1,
        shifts: [
          { shift: 'Morning', records: [] },
          { shift: 'Afternoon', records: [] },
          { shift: 'Evening', records: [] },
        ],
      };
    }

    // Iterate through each row of data
    for (let i = 1; i < data.length; i++) {
      const row = data[i];
      const driver = row[0];
      const userId = row[1];
      const armedStatus = row[2];
      const region = row[3];

      // Iterate through each day and shift
      for (let j = 4; j < headers.length; j++) {
        const shiftValue = row[j];

        if (shiftValue) {
          const dayIndex = Math.floor((j - 4) / 3); // Adjust index for days
          const shiftIndex = (j - 4) % 3; // 0 for M, 1 for A, 2 for E

          // Create a record for the shift
          const record = {
            DriverName: driver,
            UserId: userId,
            ArmedStatus: armedStatus,
            Region: region,
          };

          // Add the record to the appropriate shift
          if (days[dayIndex]) {
            days[dayIndex].shifts[shiftIndex].records.push(record);
          }
        }
      }
    }

    return Object.values(days);
  };

  const pushDataToFirestore = async () => {
    if (!jsonData) {
      setError("No data to upload.");
      return;
    }

    try {
      const shiftsCollection = collection(db, DRIVERS_SCHEDULE_TABLE); 

      // Check if the collection already has documents
      const querySnapshot = await getDocs(shiftsCollection);
      if (querySnapshot.empty) {
        console.log("Collection does not exist, creating new collection.");
      } else {
        console.log("Collection already exists.");
      }

      // Upload data to Firestore
      for (const day of jsonData) {
        await addDoc(shiftsCollection, day);
      }
      setError(null);
      alert("Data uploaded successfully!");
    } catch (err) {
      setError("Error uploading data: " + err.message);
    }
  };

  return (
    <div>
      <h1>Driver Shift Scheduler</h1>
      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
      {error && <div style={{ color: 'red' }}>{error}</div>}
      {jsonData && (
        <div>
          <ShiftCalendar data={jsonData} /> {/* Pass jsonData to ShiftCalendar */}
          <button onClick={pushDataToFirestore}>Upload to Firestore</button>
        </div>
      )}
    </div>
  );
};

export default ShiftScheduler;
