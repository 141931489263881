import { query, collection, where, getDoc, getDocs, doc } from "firebase/firestore";
import { CARS_TABLE, RYDERS_TABLE, ADMINS_TABLE, DRIVERS_TABLE, SAFE, SECURE, ULTRA_SECURE } from './constants';


export async function  getUserInfo(db, userId) {
    const ryderDoc = await getDocs(query(collection(db, RYDERS_TABLE), where('userId', '==', userId)));
    if (ryderDoc.docs.length === 1) {
        const ryder = ryderDoc.docs[0].data();
        return {id: ryderDoc.docs[0].id, firstName: ryder.firstName, lastName: ryder.lastName, phoneNumber: ryder.phoneNumber, email: ryder.email };
    }
    return null;
}

export async function  getAdminInfo(db, userId) {
    const adminDoc = await getDocs(query(collection(db, ADMINS_TABLE), where('uid', '==', userId)));
    if (adminDoc.docs.length === 1) {
        const admin = adminDoc.docs[0].data();
        return {id: adminDoc.docs[0].id, firstName: admin.firstName, lastName: admin.lastName, email: admin.email };
    }
    return null;
}

export async function getDriverId(db) {
    const driversDoc = await getDocs(collection(db, DRIVERS_TABLE));
    if (driversDoc.docs.length > 0) {
        const driver = driversDoc.docs[0];
        return driver.id;
    }
    return null;
}

export async function getDriverInfo(db, driverId) {
    const driverDoc = await getDoc(doc(db, DRIVERS_TABLE, driverId));
    if (driverDoc) {
        const driver = driverDoc.data();
        return {...driver, id: driverId };
    }
    return null;
}

export async function getCarInfo(db, carId) {
    const carDoc = await getDoc(doc(db, CARS_TABLE, carId));
    if (carDoc) {
        const car = carDoc.data();
        return {...car, id: carId };
    }
    return null;
}

export function getRideStatus(ride) {
    if (!ride.legs) {
        return 'UNKNOWN';
    }
    if (ride.status === 'CANCELED') {
        return 'CANCELED';
    }
    const nowDateTime = new Date().getTime();
    let legNotStarted = ride.legs.length;
    for (const leg of ride.legs) {
        const pickUpDateTime = new Date(leg.pickUpDateTime).getTime();
        if (pickUpDateTime < nowDateTime) {
            legNotStarted--;
        }
    }
    if (legNotStarted == ride.legs.length) {
        return 'NOT STARTED';
    } else if (legNotStarted === 0) {
        return 'COMPLETED';
    } else {
        return 'IN PROGRESS';
    }
}

export function getServiceLevel(serviceLevel) {
    if (serviceLevel === SAFE) {
        return 'Safe';
    } else if (serviceLevel === SECURE) {
        return 'Secure';
    } else if (serviceLevel === ULTRA_SECURE) {
        return 'Ultra-Secure';
    } else {
        return serviceLevel;
    }
}

export function setUpPhoneNumber(old, value) {
    if ((old === null || old === '') && value.length >= 10) {
        return `${value.replace('+', '+1')}`;
    }
    return value;
}

export function printDate(dateString) {
   const date =  new Date(dateString);
   if (isNaN(date)) {
    return 'N/A';
   }
   return date.toLocaleString('en-US', {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true});
}

export function getPaymentInfo(payment) {
    if ('paymentIntent' in payment) {
        return {id: payment.paymentIntent.id, type: payment.paymentIntent.object, amount: payment.paymentIntent.amount};
    } else {
        return {id: payment.id, type: payment.object, amount: payment.amount};
    }
}

export function getPaymentHistory(paymentConfirm) {
    if (paymentConfirm instanceof Array ) {
        return paymentConfirm;
    } else {
        return [paymentConfirm];
    }
}