import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const ShiftCalendar = ({ data }) => {
  // Generate an array for the next 7 days
  const days = Array.from({ length: 7 }, (_, i) => i + 1);

  return (
    <Grid container spacing={2}>
      {days.map((day) => {
        // Find the corresponding day data from the provided JSON
        const dayData = data.find(d => d.day === day);

        return (
          <Grid item xs={12} sm={6} md={4} key={day}>
            <Paper elevation={3} style={{ padding: '16px' }}>
              <Typography variant="h6">Day {day}</Typography>
              {dayData ? (
                dayData.shifts.map((shift) => (
                  <div key={shift.shift}>
                    <Typography variant="subtitle1">{shift.shift} Shift</Typography>
                    {shift.records.length > 0 ? (
                      shift.records.map((record, index) => (
                        <Typography key={index}>
                          {record.DriverName} - {record.ArmedStatus} ({record.Region})
                        </Typography>
                      ))
                    ) : (
                      <Typography>No drivers assigned</Typography>
                    )}
                  </div>
                ))
              ) : (
                <Typography>No data available for this day</Typography>
              )}
            </Paper>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ShiftCalendar;
