 import './Ryders.css';
 import React, {useContext, useState, useEffect, useMemo} from 'react';
 import { useNavigate } from 'react-router-dom';
 import Table from '@mui/material/Table';
 import TableBody from '@mui/material/TableBody';
 import TableCell from '@mui/material/TableCell';
 import TableContainer from '@mui/material/TableContainer';
 import TableHead from '@mui/material/TableHead';
 import TableRow from '@mui/material/TableRow';
 import TablePagination from '@mui/material/TablePagination';
 import TableSortLabel from '@mui/material/TableSortLabel';
 import Stack from '@mui/material/Stack';
 import Button from '@mui/material/Button';
 import AddIcon from '@mui/icons-material/Add';
 import EditIcon from '@mui/icons-material/Edit';
 import CircularProgress from '@mui/material/CircularProgress';
 import TextField from '@mui/material/TextField';

 import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";

 import { RYDERS_TABLE } from '../libs/constants.js';

 import { AuthContext } from '../providers/AuthContext.js';
 import { FirebaseContext } from '../providers/FirebaseContext.js';

 function Ryders () {
     const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
     const [loaded, setLoaded] = useState(false);
     const [ryders, setRyders] = useState([]);
     const [page, setPage] = useState(0);
     const [filter, setFilter] = useState(null);
     const [rowsPerPage, setRowsPerPage] = useState(5);
     const [orderBy, setOrderBy] = useState('confirm');
     const [order, setOrder] = useState('desc');
     const {auth, setAuth} = useContext(AuthContext);
     const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

     const nav = useNavigate();
     const db = getFirestore(firebaseApp);

     const handleChangePage = (event: unknown, newPage: number) => {
       setPage(newPage);
     };

     const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
       setRowsPerPage(parseInt(event.target.value, 10));
       setPage(0);
     };

     const fetchRyders = async () => {
       getDocs(collection(db, RYDERS_TABLE))
               .then((querySnapshot)=>{
                    const newRyders = querySnapshot.docs.map((ryder) =>
                             ({id: ryder.id, firstName: ryder.data().firstName,
                              lastName: ryder.data().lastName, email: ryder.data().email,
                              phoneNumber: ryder.data().phoneNumber}));
                   setRyders(newRyders);
                   setLoaded(true);
               })
     };

     useEffect(()=>{
         fetchRyders();
     }, []);

     useEffect(() => {
         function handleResize() {
             setViewPortWidth(window.innerWidth);
         }
         window.addEventListener('resize', handleResize)
     });

     const filterRows = (rows) => {
       if (filter === null) {
           return rows;
       }
       return rows.filter((row) => {
           const filterLowerCase = filter.toLowerCase();
           return (row.firstName.toLowerCase().includes(filterLowerCase)||
                   row.lastName.toLowerCase().includes(filterLowerCase) ||
                   row.email.toLowerCase().includes(filterLowerCase) ||
                   (row.phoneNumber.toLowerCase() && row.phoneNumber.toLowerCase().includes(filterLowerCase)));
       });
     };

     const sortRows = (rows) => {
       return rows.sort((a, b) =>  {
           if (orderBy === 'firstName') {
               if (order === 'asc') {
                   return a.firstName.localeCompare(b.firstName);
               } else {
                   return b.firstName.localeCompare(a.firstName);
               }
           } else if (orderBy === 'lastName') {
               if (order === 'asc') {
                   return a.lastName.localeCompare(b.lastName);
               } else {
                   return b.lastName.localeCompare(a.lastName);
               }
           } else if (orderBy === 'email') {
                if (order === 'asc') {
                    return a.email.localeCompare(b.email);
                } else {
                    return b.email.localeCompare(a.email);
                }
           } else if (orderBy === 'phoneNumber') {
                 if (order === 'asc') {
                     return a.phoneNumber.localeCompare(b.phoneNumber);
                 } else {
                     return b.phoneNumber.localeCompare(a.phoneNumber);
                 }
           } else {
               return 0;
           }

       })
     };

     const filteredRows = useMemo(() => filterRows(sortRows(ryders)), [ryders, filter, order, orderBy]);

     const visibleRows = useMemo(
         () => filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
         [page, rowsPerPage, filteredRows, filter, order, orderBy],
       );

     const onColumnClick = (header) => {
       if (header === orderBy) {
           setOrder(order !== 'asc' ? 'asc': 'desc');
       } else {
           setOrderBy(header);
           setOrder('asc');
       }
     };

     /*useEffect(() => {
         if (!auth || !auth?.loggedIn) {
           nav('/signin');
         }
     });*/

     if (visibleRows.length === 0 && !loaded) {
       return (<div className="Loading"><CircularProgress/></div>);
     }

     return ( <div className="Ryders">
         <div className="RydersButton">
           <TextField label="Search" variant="outlined" type="text" value={filter} size={"small"} onChange={(event) => {setFilter(event.target.value)}}/>
         </div>
         <Table>
           <TableHead>
             <TableRow>
               <TableCell key={'firstName'}><TableSortLabel active={orderBy === 'firstName'} onClick={() => {onColumnClick('firstName'); } } direction={orderBy === 'firstName' ? order : 'asc'}>First Name</TableSortLabel></TableCell>
               <TableCell key={'lastName'}><TableSortLabel active={orderBy === 'lastName'} onClick={() => {onColumnClick('lastName');} } direction={orderBy === 'lastName'? order : 'asc'}>Last Name</TableSortLabel></TableCell>
               <TableCell key={'email'}><TableSortLabel active={orderBy === 'email'} onClick={() => {onColumnClick('email');} } direction={orderBy === 'email'? order : 'asc'}>Email</TableSortLabel></TableCell>
               <TableCell key={'phoneNumber'}><TableSortLabel active={orderBy === 'phoneNumber'} onClick={() => {onColumnClick('phoneNumber');} } direction={orderBy === 'phoneNumber'? order : 'asc'}>Phone Number</TableSortLabel></TableCell>
               <TableCell key={'actions'}>Actions</TableCell>
             </TableRow>
           </TableHead>
           <TableBody>
             {
                 visibleRows.map( (ryder,i) => {
                   const keyName = `${ryder.type}_${i}`;
                   return (
                     <TableRow key={keyName}>
                       <TableCell>{ryder.firstName}</TableCell>
                       <TableCell>{ryder.lastName}</TableCell>
                       <TableCell>{ryder.email}</TableCell>
                       <TableCell>{ryder.phoneNumber}</TableCell>
                       <TableCell><Button><EditIcon /></Button></TableCell>
                     </TableRow>
                   );
                 })
             }
             </TableBody>
         </Table>
         <TablePagination
           rowsPerPageOptions={[5, 10, 25]}
           component="div"
           count={filteredRows.length}
           rowsPerPage={rowsPerPage}
           page={page}
           onPageChange={handleChangePage}
           onRowsPerPageChange={handleChangeRowsPerPage}
         />
       </div>);

   }

 export default Ryders;
