import './Signup.css';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AppleIcon from '@mui/icons-material/Apple';
import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';

import { getAuth, signInWithPopup, createUserWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, addDoc, collection } from "firebase/firestore";

import { RYDERS_TABLE } from '../libs/constants.js';
import { setUpPhoneNumber } from '../libs/utils.js';

import MuiPhoneNumber from 'mui-phone-number';

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

const Mode = Object.freeze({
    SIGNUP: 0,
    APPLE: 1,
    GOGGLE: 2,
    EMAIL: 3,
    INFO: 4,
});

function Signup(props) {
    const [mode, setMode] = useState(Mode.SIGNUP);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [processing, setProcessing] = useState(false);

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);

    const [error, setError] = useState(null);

    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const authFromApp = getAuth(firebaseApp);
    const provider = new GoogleAuthProvider();

    const signUpUsingEmail = () => {
        setMode(Mode.EMAIL);
    };

    const signUpUsingGoogle = () =>{
        setMode(Mode.GOOGLE);
    }

    const onFirstNameChange = (event: ChangeEvent) => {
       setFirstName(event.target.value);
    };

    const onLastNameChange = (event: ChangeEvent) => {
       setLastName(event.target.value);
    };

    const onPhoneNumberChange = (value) => {
       let newPhoneNumber = setUpPhoneNumber(phoneNumber, value);
       setPhoneNumber(newPhoneNumber);
    };

    const onEmailChange = (event: ChangeEvent) => {
       setEmail(event.target.value);
    };

    const onPasswordChange = (event: ChangeEvent) => {
       setPassword(event.target.value);
    };

    const onConfirmPasswordChange = (event: ChangeEvent) => {
       setConfirmPassword(event.target.value);
    };

    const validUserInfo = () => {
        if (firstName === '') {
            setError('First name is empty.');
            return false;
        }
        if (lastName === '') {
            setError('Last name is empty.');
            return false;
        }
        if (lastName === '') {
            setError('Phone number is empty.');
            return false;
        }
        return true;
    };

    const onSignUpEmailPassword = () => {
        if (email === null || email.length < 5 || !email.includes('@') || !email.includes('.')) {
            setError('Invalid email address');
            return;
        }
        if (password === null || password.length < 8 || password.length >  12) {
            setError('Password length needs to be 8 - 12 characters.');
            return;
        }
        if ( password !== confirmPassword ) {
            setError('Password does not match.');
            return;
        }
        if (!validUserInfo()) {
            return;
        }
        setProcessing(true);
        createUserWithEmailAndPassword(authFromApp, email, password)
          .then((result) => {
            // Signed up
            console.log(result);
            return result.user;
          })
          .then((user) => {
            addUserInfo(user);
          }).catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorMessage);
            setProcessing(false);
          });
    };

    const onSignUpGoogle = () => {
        if (!validUserInfo()) {
            return;
        }
        setProcessing(true);
        signInWithPopup(authFromApp, provider)
          .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;
            // The signed-in user info.
            return result.user;
          }).then((user) => {
            addUserInfo(user);
          }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            setError(errorMessage);
            setProcessing(false);
          });

    };

    const addUserInfo = (user) => {
        const db = getFirestore(firebaseApp);
        addDoc(collection(db, RYDERS_TABLE),
            {
              firstName: firstName,
              lastName: lastName,
              phoneNumber: phoneNumber,
              email: user.email,
              userId: user.uid,
            }
         ).then(()=> {
            const userInfo = {firstName: firstName, lastName: lastName, phoneNumber: phoneNumber, email: user.email};
            setAuth({loggedIn: true, user: user, userInfo: userInfo});
            if (props.afterSignUp) {
                props.afterSignUp(userInfo);
            } else {
                nav('/');
            }
         });
    };

    if (mode === Mode.SIGNUP) {
        return (
            <div className="Signup">
                <Card variant="outlined">
                    <CardHeader title="Sign up"/>
                    <CardContent>
                        <Stack spacing={2} direction="column">
                            {error && (<div className="error">{error}</div>)}
                            <Button variant="outlined" onClick={signUpUsingEmail}><EmailIcon/>&nbsp;&nbsp;Email/Password</Button>
                            <Button variant="outlined" onClick={signUpUsingGoogle}><GoogleIcon/>&nbsp;&nbsp;Google</Button>
                        </Stack>
                    </CardContent>
                </Card>
            </div>
        );
    } else if (mode === Mode.EMAIL) {
        return (
            <div className="Signup">
                <Card variant="outlined">
                    <CardHeader title="Email/Password"/>
                    <CardContent>
                        <Stack spacing={2} direction="column">
                            {error && (<div className="error">{error}</div>)}
                            <TextField label="First Name" variant="outlined" value={firstName} onChange={onFirstNameChange}/>
                            <TextField label="Last Name" variant="outlined" value={lastName} onChange={onLastNameChange} />
                            <MuiPhoneNumber variant="outlined" label="Phone Number" defaultCountry={'us'} value={phoneNumber} onChange={onPhoneNumberChange} />
                            <TextField label="Email" variant="outlined" value={email} onChange={onEmailChange} type="email"/>
                            <TextField label="Password" variant="outlined" value={password} onChange={onPasswordChange} type="password"/>
                            <TextField label="Confirm Password" variant="outlined" value={confirmPassword}  onChange={onConfirmPasswordChange} type="password"/>
                            <div>
                                <Button variant="contained" onClick={onSignUpEmailPassword} disabled={processing}>SIGN UP</Button>
                            </div>
                        </Stack>
                    </CardContent>
                </Card>
            </div>
            );
    } else if (mode === Mode.GOOGLE) {
            return (
                <div className="Signup">
                    <Card variant="outlined">
                        <CardHeader title="Google"/>
                        <CardContent>
                            <Stack spacing={2} direction="column">
                                {error && (<div className="error">{error}</div>)}
                                <TextField label="First Name" variant="outlined" value={firstName} onChange={onFirstNameChange}/>
                                <TextField label="Last Name" variant="outlined" value={lastName} onChange={onLastNameChange} />
                                <MuiPhoneNumber variant="outlined" label="Phone Number" defaultCountry={'us'} value={phoneNumber} onChange={onPhoneNumberChange} />
                                <div>
                                    <Button variant="contained" onClick={onSignUpGoogle} disabled={processing}>SIGN UP</Button>
                                </div>
                            </Stack>
                        </CardContent>
                    </Card>
                </div>
                );
    }
}

export default Signup;