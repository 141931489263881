import './SignIn.css';
import React, {useContext, useState, ChangeEvent} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import GoogleIcon from '@mui/icons-material/Google';
import { IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; 

import { getAuth, signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import { getUserInfo, getAdminInfo } from '../libs/utils.js';

function SignIn(props) {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const {firebaseApp} = useContext(FirebaseContext);
  const {auth, setAuth} = useContext(AuthContext);

  const nav = useNavigate();
  const authFromApp = getAuth(firebaseApp);
  const db = getFirestore(firebaseApp);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }; 

  const handleMouseDown = (e) => {
    e.preventDefault();
  };

  const signInUsingEmailPassword = () => {
    signInWithEmailAndPassword(authFromApp, email, password)
      .then(async (result) => {
        const user = result.user;
        const adminInfo = await getAdminInfo(db, user.uid);
        if (adminInfo != null) {
            setAuth({loggedIn: true, user: user, adminInfo: adminInfo});
            nav('/rides');
        } else {
            setError('Invalid login');
        }
      }).catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        setError(errorMessage);
      });

  };

  const onEmailChange = (event: ChangeEvent) => {
    setEmail(event.target.value);
  };

  const onPasswordChange = (event: ChangeEvent) => {
    setPassword(event.target.value);
  };

  return (
    <div className="SignIn">
        <Stack spacing={4} direction="column">
            <Card variant="outlined">
                <CardHeader title="Sign In">
                </CardHeader>
                <CardContent>
                    <Stack spacing={2} direction="column">
                {error && (<Typography variant="h6" color={'red'}>Login failed! <br/>{error}</Typography>)}
                        <TextField value={email} label="Email" variant="outlined" type="email" onChange={onEmailChange}/>
                        <TextField value={password}  label="Password" variant="outlined" type={showPassword? 'text': 'password'} onChange={onPasswordChange} 
                        InputProps={{
                          endAdornment:(
                            <InputAdornment position='end'>
                              <IconButton onClick={toggleShowPassword} onMouseDown={handleMouseDown} edge='end'>
                                {showPassword? <Visibility sx={{ color: '#1976d2' }} />: <VisibilityOff/>}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        />
                        <Button variant="outlined" onClick={signInUsingEmailPassword}><EmailIcon/>&nbsp;&nbsp;Email/Password</Button>
                    </Stack>
                </CardContent>
            </Card>
            <div className="SignInLinks"><Link to="/signup">Request an account</Link><Link to="/resetpassword">Forgot password</Link></div>
    	</Stack>
    </div>
  );
}

export default SignIn;
