import './ResetPassword.css';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { FirebaseContext } from '../providers/FirebaseContext.js';

function ResetPassword() {
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(null);
    const nav = useNavigate();

    const {firebaseApp} = useContext(FirebaseContext);

    const onEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const sendResetLink = () => {
        const auth = getAuth();
        sendPasswordResetEmail(auth, email)
          .then(() => {
            nav('/signin');
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            setError(errorMessage);
          });
    };

    return (
        <div className="ResetPassword">
            <Card variant="outlined">
                <CardHeader title="Reset Password">
                </CardHeader>
                <CardContent>
                    <Stack spacing={2} direction="column">
                        {error && (<div className="error">{error}</div>)}
                        <TextField label="Email" variant="outlined" onChange={onEmailChange} type="email"/>
                        <Button variant="outlined" onClick={sendResetLink}>Send Reset Link</Button>
                    </Stack>
                </CardContent>
            </Card>
        </div>
    );
}

export default ResetPassword;