import './Rides.css';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';

import { getFirestore, query, collection, where, getDocs } from "firebase/firestore";

import { AuthContext } from '../providers/AuthContext.js';
import { FirebaseContext } from '../providers/FirebaseContext.js';

import { BOOKED_RIDES_TABLE, DRIVERS_TABLE, VIEWPORT_LIMIT } from '../libs/constants.js';

import CancelRide from '../components/CancelRide';
import RideDetails from '../components/RideDetails';
import BookRide from './BookRide';
import BookRideEdit from '../components/BookRideEdit';

function Rides () {
    const [viewPortWidth, setViewPortWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const [rides, setRides] = useState([]);
    const [page, setPage] = useState(0);
    const [filter, setFilter] = useState(null);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [selectedRide, setSelectedRide] = useState(null);
    const [orderBy, setOrderBy] = useState('confirm');
    const [order, setOrder] = useState('desc');
    const [mode, setMode] = useState(null);
    const {auth, setAuth} = useContext(AuthContext);
    const {firebaseApp, setFirebaseApp} = useContext(FirebaseContext);

    const nav = useNavigate();
    const db = getFirestore(firebaseApp);

    const priceFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

    const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    const hasDriver = (bookedRide) => {
        if (!bookedRide.legs) {
            return false;
        }
        for (const leg of bookedRide.legs) {
            if (!leg.driverId) {
                return false;
            }
        }
        return true;
    }

    const getDriver = (bookedRide, drivers) => {
            if (!bookedRide.legs) {
                return false;
            }
            for (const leg of bookedRide.legs) {
                if (leg.driverId) {
                    const driver = drivers.get(leg.driverId);
                    return `${driver?.personalInfo?.lastName || 'N/A'}, ${driver?.personalInfo?.firstName || 'N/A'}`;
                }
            }
            return 'None';
        }

    const fetchRides = async (drivers) => {
      getDocs(query(collection(db, BOOKED_RIDES_TABLE)))
            .then((querySnapshot)=>{
                const newData = querySnapshot.docs
                    .map((doc) => ({...doc.data(), id:doc.id, driver: getDriver(doc.data(), drivers)}));
                setRides(newData);
                setLoaded(true);
            })

    };

    const fetchDrives = async () => {
      return getDocs(collection(db, DRIVERS_TABLE))
              .then((querySnapshot)=>{
                  const driversMap = new Map();
                  for (const driver of querySnapshot.docs) {
                      driversMap.set(driver.id, {...driver.data(), id:driver.id });
                  }
                  return new Promise((resolve, reject) => {
                    return resolve(driversMap);
                  });
              })
    };

    useEffect(()=>{
        fetchDrives().then((drivers) => {
            fetchRides(drivers);
        });

    }, []);

    useEffect(() => {
        function handleResize() {
            setViewPortWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize)
    });

    const getPickUpDateString = (legs) => {
        return legs ? legs[0].pickUpDateTime ?
                new Date(legs[0].pickUpDateTime).toLocaleString('en-US', {
                  weekday: 'short',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric',
                  hour: 'numeric',
                  minute: 'numeric',
                  hour12: true
                })
                : 'N/A'
              : 'N/A';
    }

    const filterRows = (rows) => {
      if (filter === null) {
          return rows;
      }
      return rows.filter((row) => {
          const confirmationCode = `${row.confirmationCode}`;
          const fullName = row.primary ?
                    row.primary.firstName + (row.primary.lastName ? ` ${row.primary.lastName}` : '')
                    : 'N/A';
          const dateOfService = getPickUpDateString(row.legs);
          const pickUpLocation = row.legs ? row.legs[0].from: 'N/A';
          const dropOffLocation = row.legs ? row.legs[0].to: 'N/A';
          const driver = row.driver;
          const filterLowerCase = filter.toLowerCase();
          return (confirmationCode.toLowerCase().includes(filterLowerCase) ||
                  driver.toLowerCase().includes(filterLowerCase) ||
                  fullName.toLowerCase().includes(filterLowerCase) ||
                  dateOfService.toLowerCase().includes(filterLowerCase) ||
                  pickUpLocation.toLowerCase().includes(filterLowerCase) ||
                  dropOffLocation.toLowerCase().includes(filterLowerCase));
      });
    };

    const sortRows = (rows) => {
      return rows.sort((a, b) =>  {
          if (orderBy === 'confirm') {
              const confirmationCodeA = `${a.confirmationCode}`;
              const confirmationCodeB = `${b.confirmationCode}`;
              if (order === 'asc') {
                  return confirmationCodeA.localeCompare(confirmationCodeB);
              } else {
                  return confirmationCodeB.localeCompare(confirmationCodeA);
              }
          } else if (orderBy === 'fullname') {
              const fullNameA = a.primary ?
                              a.primary.firstName + (a.primary.lastName ? ` ${a.primary.lastName}` : '')
                                : 'N/A';
              const fullNameB = b.primary ?
                              b.primary.firstName + (b.primary.lastName ? ` ${b.primary.lastName}` : '')
                              : 'N/A';
              if (order === 'asc') {
                  return fullNameA.localeCompare(fullNameB);
              } else {
                  return fullNameB.localeCompare(fullNameA);
              }
          } else if (orderBy === 'driver') {
            const driverA = a.driver;
            const driverB = b.driver;
            if (order === 'asc') {
                  return driverA.localeCompare(driverB);
              } else {
                  return driverB.localeCompare(driverA);
              }
          } else if (orderBy === 'date' && a.legs && b.legs) {
              const pickUpDateA = new Date(a.legs[0].pickUpDateTime).getTime();
              const pickUpDateB = new Date(b.legs[0].pickUpDateTime).getTime();
              if (order === 'asc') {
                  if(pickUpDateA > pickUpDateB) {
                      return 1;
                  } else if(pickUpDateA < pickUpDateB) {
                      return -1;
                  } else {
                      return 0;
                  }
              } else {
                  if(pickUpDateB > pickUpDateA) {
                      return 1;
                  } else if(pickUpDateB < pickUpDateA) {
                      return -1;
                  } else {
                      return 0;
                  }
              }
          } else if (orderBy === 'pickup' && a.legs && b.legs) {
              const pickupA = a.legs[0].from;
              const pickupB = b.legs[0].from;
              if (order === 'asc' ) {
                  return pickupA.localeCompare(pickupB);
              } else {
                  return pickupB.localeCompare(pickupA);
              }
          } else if (orderBy === 'dropoff' && a.legs && b.legs) {
                const dropOffA = a.legs[0].to;
                const dropOffB = b.legs[0].to;
                if (order === 'asc') {
                    return dropOffA.localeCompare(dropOffB);
                } else {
                    return dropOffB.localeCompare(dropOffA);
                }
          } else {
              return 0;
          }

      })
    };

    const filteredRows = useMemo(() => filterRows(sortRows(rides)), [rides, filter, order, orderBy]);

    const visibleRows = useMemo(
        () => filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [page, rowsPerPage, filteredRows, filter, order, orderBy],
      );

    const onColumnClick = (header) => {
      if (header === orderBy) {
          setOrder(order !== 'asc' ? 'asc': 'desc');
      } else {
          setOrderBy(header);
          setOrder('asc');
      }
    };

   /*useEffect(() => {
        if (!auth || !auth?.loggedIn) {
          nav('/signin');
        }
    });*/

    if (visibleRows.length === 0 && !loaded) {
      return (<div className="Loading"><CircularProgress/></div>);
    }

    if (selectedRide) {
        if (mode === 'edit') {
            return (
                  <>
                      <BookRideEdit ride={selectedRide} onBack={() => {setSelectedRide(null); setMode(null);}} onEdit={() => {setSelectedRide(null); setMode(null); fetchRides();} } />
                  </>
              );
        } else if (mode === 'view') {
            return (
                  <>
                      <RideDetails ride={selectedRide} onBack={() => {setSelectedRide(null); setMode(null);}} />
                  </>
              );
        } else if (mode === 'cancel') {
            return (
                  <>
                      <CancelRide ride={selectedRide} onBack={() => {setSelectedRide(null); setMode(null);}} onAction={() => {setSelectedRide(null); setMode(null); fetchRides();} } />
                  </>
              );
        }

    }
    return ( <div className="Rides">
        <div className="RidesButton">
          <TextField label="Search" variant="outlined" type="text" value={filter} size={"small"} onChange={(event) => {setFilter(event.target.value)}}/>
          &nbsp;<Button variant="outlined" onClick={() => {nav('/schedule');} }>Schedule Ride</Button>
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell key={'fullname'}><TableSortLabel active={orderBy === 'fullname'} onClick={() => {onColumnClick('fullname'); } } direction={orderBy === 'fullname' ? order : 'asc'}>Full Name</TableSortLabel></TableCell>
              <TableCell key={'date'}><TableSortLabel active={orderBy === 'date'} onClick={() => {onColumnClick('date');} } direction={orderBy === 'date'? order : 'asc'}>Date</TableSortLabel></TableCell>
              <TableCell key={'confirm'}><TableSortLabel active={orderBy === 'confirm'} onClick={() => {onColumnClick('confirm');} } direction={orderBy === 'confirm'? order : 'asc'}>Confirmation</TableSortLabel></TableCell>
              <TableCell key={'driver'}><TableSortLabel active={orderBy === 'driver'} onClick={() => {onColumnClick('driver');} } direction={orderBy === 'driver'? order : 'asc'}>Driver</TableSortLabel></TableCell>
              <TableCell key={'pickup'}><TableSortLabel active={orderBy === 'pickup'} onClick={() => {onColumnClick('pickup');} } direction={orderBy === 'pickup'? order : 'asc'}>Pickup Location</TableSortLabel></TableCell>
              <TableCell key={'dropoff'}><TableSortLabel active={orderBy === 'dropoff'} onClick={() => {onColumnClick('dropoff');} } direction={orderBy === 'dropoff'? order : 'asc'}>Drop-off Location</TableSortLabel></TableCell>
              <TableCell key={'actions'}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
                visibleRows.map( (ride,i) => {
                  const keyName = `${ride.type}_${i}`;
                  const fullName = ride.primary ?
                  ride.primary.firstName + (ride.primary.lastName ? ` ${ride.primary.lastName}` : '')
                  : 'N/A';
                  const dateOfService = ride.legs ?
                  ride.legs[0].pickUpDateTime ?
                    new Date(ride.legs[0].pickUpDateTime).toLocaleString('en-US', {
                      weekday: 'short',
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: true
                    })
                    : 'N/A'
                  : 'N/A';
                  const pickUpLocation = ride.legs ? ride.legs[0].from: 'N/A';
                  const dropOffLocation = ride.legs ? ride.legs[0].to: 'N/A';
                  return (
                    <TableRow>
                      <TableCell>{fullName}</TableCell>
                      <TableCell>{dateOfService}</TableCell>
                      <TableCell>{ride.confirmationCode}</TableCell>
                      <TableCell>{ride.driver}</TableCell>
                      <TableCell>{pickUpLocation}</TableCell>
                      <TableCell>{dropOffLocation}</TableCell>
                      <TableCell>
                        <Stack direction="row">
                            <Button onClick={() => { setSelectedRide(ride); setMode('view'); }}><PreviewIcon />
                            </Button>
                            { ride.status !== 'CANCELED' && (<Button onClick={() => { setSelectedRide(ride); setMode('edit'); }}><EditIcon />
                            </Button>)}
                            { ride.status !== 'CANCELED' && (<Button onClick={() => { setSelectedRide(ride); setMode('cancel'); }}><ClearIcon />
                            </Button>)}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })
            }
            </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>);
  }

export default Rides;